import { configureStore } from '@reduxjs/toolkit';
import { 
  persistStore, 
  persistReducer, 
  FLUSH, 
  REHYDRATE, 
  PAUSE, 
  PERSIST, 
  PURGE, 
  REGISTER 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { serialize } from 'reqwest';
import rootReducer from 'store/rootReducer';

const middlewares = [];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'theme', 'products', 'alexa', 'onePage', 'mobileApps'],
  debug: true,
  stateReconciler: (inboundState, originalState, reducedState, config) => {
    const parsedState = typeof inboundState === 'string' ? JSON.parse(inboundState) : inboundState;
    return parsedState;
  }
};

const createRootReducer = (asyncReducers = {}) => {
  const appReducer = rootReducer(asyncReducers);
  
  return (state, action) => {
    if (action.type === 'auth/signOut/fulfilled') {
      storage.removeItem('persist:root');
      state = undefined;
    }
    return appReducer(state, action);
  };
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store, null, () => {
});


store.asyncReducers = {};

const forcePersistUpdate = async () => {
  try {
    await persistor.persist();
    const currentState = store.getState();
    await storage.setItem(
      'persist:root',
      JSON.stringify({
        ...currentState,
        auth: JSON.stringify(currentState.auth)
      })
    );
  } catch (error) {
    console.error('Error forcing persist update:', error);
  }
};

export const injectReducer = (key, asyncReducer) => {
  if (store.asyncReducers[key]) return;

  store.asyncReducers[key] = asyncReducer;
  store.replaceReducer(
    persistReducer(
      persistConfig,
      createRootReducer(store.asyncReducers)
    )
  );

  forcePersistUpdate();
};

export { store, persistor };