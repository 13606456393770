// store/slices/mobileAppSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMobileApps } from 'services/api';
import { signOut } from './authSlice'; 


export const fetchMobileApps = createAsyncThunk('mobileapps/fetchMobileApps', async () => {
  const response = await getMobileApps();
  return response;  
});

const mobileAppSlice = createSlice({
  name: 'mobileapps',
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (state) => {
      return {
        items: [],
        loading: false,
        error: null,
      };
    },
  },
    extraReducers: (builder) => {
    builder
      .addCase(fetchMobileApps.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMobileApps.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchMobileApps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signOut.fulfilled, (state) => {
        return {
          items: [],
          loading: false,
          error: null,
        };
      });
  },
});

export const { reset } = mobileAppSlice.actions;

export default mobileAppSlice.reducer;
